<template>
  <div class="model-viewer">
    <div ref="container"></div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

export default {
  name: 'ModelViewer',
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      controls: null,
      model: null,
      mixer: null,
      animations: [],
      currentAction: null
    }
  },
  mounted() {
    this.initThreeJS()
    this.loadModel()
    this.animate()
  },
  methods: {
    initThreeJS() {
      // 创建场景
      this.scene = new THREE.Scene()
      
      // 创建相机
      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      )
      this.camera.position.z = 5
      
      // 创建渲染器
      this.renderer = new THREE.WebGLRenderer()
      this.renderer.setSize(window.innerWidth, window.innerHeight)
      this.renderer.setClearColor(0xffffff)
      this.$refs.container.appendChild(this.renderer.domElement)
      
      // 添加轨道控制
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      
      // 添加环境光
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
      this.scene.add(ambientLight)
      
      // 添加方向光
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
      directionalLight.position.set(0, 1, 0)
      this.scene.add(directionalLight)

      // 监听窗口大小变化
      window.addEventListener('resize', this.onWindowResize)
    },
    loadModel() {
      const loader = new GLTFLoader()
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
      loader.setDRACOLoader(dracoLoader)
      loader.load(
        '/models/scene.glb',
        (gltf) => {
          this.model = gltf.scene
           // ============ 新增：设置模型位置 ============
          this.model.position.set(0, -4, 0) // (x, y, z) 坐标
          // this.model.position.y = 2 // 也可单独设置某一轴向
          // this.model.rotation.y = Math.PI // 如需旋转可添加
          // ===========================================
          this.scene.add(this.model)
          this.model.scale.set(0.8, 0.8, 0.8)
          
          // 设置动画
          this.mixer = new THREE.AnimationMixer(this.model)
          this.animations = gltf.animations
          
          // 找到说话动画
          const talkAnimation = this.animations.find(anim => 
            anim.name.toLowerCase().includes('talk') || 
            anim.name.toLowerCase().includes('speak')
          )
          
          if (talkAnimation) {
            this.currentAction = this.mixer.clipAction(talkAnimation)
            // 设置动画速度（0.5表示半速，数值越小越慢）
            this.currentAction.timeScale = 0.5  // 设置为0.1倍速，即原速度的十分之一  // 设置为0.3倍速
            // 设置动画循环模式
            this.currentAction.setLoop(THREE.LoopRepeat)
          }
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total * 100) + '% loaded')
        },
        (error) => {
          console.error('An error occurred loading the model:', error)
        }
      )
    },
    animate() {
      requestAnimationFrame(this.animate)
      
      // 更新动画混合器
      if (this.mixer) {
        this.mixer.update(0.016) // 假设60fps
      }
      
      this.renderer.render(this.scene, this.camera)
    },
    onWindowResize() {
      if (this.camera && this.renderer) {
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(window.innerWidth, window.innerHeight)
      }
    },
    // 控制说话动画
    startTalkAnimation() {
      if (this.currentAction) {
        this.currentAction.reset()
        this.currentAction.setLoop(THREE.LoopRepeat)  // 设置为循环播放
        this.currentAction.clampWhenFinished = false  // 不在结束时锁定
        this.currentAction.play()
      }
    },
    stopTalkAnimation() {
      if (this.currentAction) {
        this.currentAction.fadeOut(0.5)  // 添加0.5秒的淡出效果
        setTimeout(() => {
          this.currentAction.stop()
        }, 500)
      }
    }
  },
  beforeDestroy() {
    if (this.model) {
      this.scene.remove(this.model)
    }
    if (this.mixer) {
      this.mixer.stopAllAction()
    }
    this.renderer.dispose()
    window.removeEventListener('resize', this.onWindowResize)
  }
}
</script>

<style scoped>
.model-viewer {
  width: 100%;
  height: 100%;
}

.model-viewer div {
  width: 100% !important;
  height: 100% !important;
}
</style>
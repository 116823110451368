<template>
  <div class="speech-recognition">
    <div class="text-panel pointer-events-auto" v-if="isRecording || transcriptHistory.length > 0">
      <div class="processing-status" v-if="isProcessing">
        AI思考中...
        <button @click="cancelResponse" class="cancel-btn">取消</button>  
      </div>
      <div v-for="(item, index) in transcriptHistory" 
           :key="index" 
           :class="['transcript-item', item.type]">
        <span class="message-tag">{{ item.type === 'user' ? '我' : 'AI' }}:</span>
        {{ item.text }}
      </div>
      <div v-if="interimTranscript" class="interim-text">
        {{ interimTranscript }}
      </div>
    </div>

    <!-- 底部控制面板 -->
    <div class="controls-panel">
      <select v-model="selectedDevice" class="microphone-select">
        <option value="">选择麦克风</option>
        <option v-for="device in audioDevices" 
                :key="device.deviceId" 
                :value="device.deviceId">
          {{ device.label || `麦克风 ${device.deviceId}` }}
        </option>
      </select>
      <button 
        @click="toggleRecording" 
        :class="['dialog-btn', isRecording ? 'active' : '']"
        :disabled="!selectedDevice">
        {{ isRecording ? '结束对话' : '开始对话' }}
      </button>
      <span v-if="isRecording" class="recording-status">录音中...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpeechRecognition',
  
  data() {
    return {
      audioDevices: [],
      selectedDevice: '',
      recognition: null,
      isRecording: false,
      interimTranscript: '',
      transcriptHistory: [],
      chatId: null,
      baseUrl: 'https://bxlk.youqua.cn/jimu',
      isProcessing: false,
      currentController: null,
      // 新增TTS相关数据
      synth: window.speechSynthesis,
      isSpeaking: false,
      currentUtterance: null,
      accumulatedText: '',  // 用于累积文本
      speechQueue: [],      // 语音队列
      talkAnimation: null,
    }
  },

  mounted() {
    this.initAll()
  },

  methods: {
    async initAll() {
      await this.getAudioDevices()
      this.initSpeechRecognition()
      await this.initChatSession()
      // 初始化语音合成设置
      this.initSpeechSynthesis()
    },

    initSpeechSynthesis() {
      // 监听语音播放结束事件
      window.speechSynthesis.onvoiceschanged = () => {
        // 获取中文声音
        const voices = this.synth.getVoices()
        console.log(voices)
        const chineseVoice = voices.find(voice => voice.lang.includes('zh'))
        if (chineseVoice) {
          this.defaultVoice = chineseVoice
        }
      }
    },


    speakText(text) {
      const utterance = new SpeechSynthesisUtterance(text)
      if (this.defaultVoice) {
        utterance.voice = this.defaultVoice
      }
      utterance.lang = 'zh-CN'
      utterance.rate = 2
      utterance.pitch = 1

      // 添加开始和结束事件监听
      utterance.onstart = () => {
        this.isSpeaking = true
        this.$emit('talkStart')
      }

      utterance.onend = () => {
        this.isSpeaking = false
        this.$emit('talkEnd')
        this.processNextSpeech()
      }

      // 如果当前正在播放，将新的文本加入队列
      if (this.isSpeaking) {
        this.speechQueue.push(utterance)
      } else {
        this.synth.speak(utterance)
        this.currentUtterance = utterance
      }
    },


    processNextSpeech() {
      if (this.speechQueue.length > 0) {
        const nextUtterance = this.speechQueue.shift()
        this.synth.speak(nextUtterance)
        this.currentUtterance = nextUtterance
      }
    },
    async getAudioDevices() {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true })
        const devices = await navigator.mediaDevices.enumerateDevices()
        this.audioDevices = devices.filter(device => device.kind === 'audioinput')
      } catch (error) {
        console.error('Error accessing audio devices:', error)
      }
    },
 
    initSpeechRecognition() {
      if (!('webkitSpeechRecognition' in window)) {
        alert('您的浏览器不支持语音识别功能')
        return
      }
      
      this.recognition = new webkitSpeechRecognition()
      this.recognition.continuous = true
      this.recognition.interimResults = true
      this.recognition.lang = 'zh-CN'
      
      this.recognition.onresult = (event) => {
        let finalTranscript = ''
        let interimTranscript = ''
 
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript
          if (event.results[i].isFinal) {
            finalTranscript += transcript
          } else {
            interimTranscript += transcript
          }
        }
 
        this.interimTranscript = interimTranscript
 
        if (finalTranscript) {
          this.transcriptHistory.push({
            type: 'user',
            text: finalTranscript
          })
          this.sendToBackend(finalTranscript)
        }
      }
      
      this.recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error)
        if (event.error === 'network') {
          this.handleNetworkError()
        }
      }
 
      this.recognition.onend = () => {
        if (this.isRecording) {
          this.restartRecognition()
        }
      }
    },
 
    async initChatSession() {
      try {
        const response = await fetch(`${this.baseUrl}/create/chat`)
        const data = await response.json()
        if (data.data) {
          this.chatId = data.data.chatId
          console.log('聊天会话创建成功:', this.chatId)
        } else {
          throw new Error(data.msg || '创建会话失败')
        }
      } catch (error) {
        console.error('初始化会话错误:', error)
        alert('初始化会话失败，请刷新重试')
      }
    },
 
    async sendToBackend(text) {
      if (!this.chatId) {
        console.error('没有有效的会话ID')
        await this.initChatSession()
        return
      }

      if (this.currentController) {
        this.currentController.abort()
      }
      
      this.currentController = new AbortController()
      this.isProcessing = true
      this.accumulatedText = ''  // 重置累积的文本

      try {
        const response = await fetch(`${this.baseUrl}/chat/test`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: text,
            chatId: this.chatId
          }),
          signal: this.currentController.signal
        })

        const responseIndex = this.transcriptHistory.length
        this.transcriptHistory.push({
          type: 'ai',
          text: ''
        })

        const reader = response.body.getReader()
        const decoder = new TextDecoder()
        
        while (true) {
          const { done, value } = await reader.read()
          if (done) break
          
          const text = decoder.decode(value)
          const lines = text.split('\n')
          
          for (const line of lines) {
            try {
              if (line.trim().startsWith('data: ')) {
                const jsonStr = line.trim().replace('data: ', '')
                const parsed = JSON.parse(jsonStr)
                if (parsed.content) {
                  this.transcriptHistory[responseIndex].text += parsed.content
                  // 累积文本
                  this.accumulatedText += parsed.content
                  
                  // 当收集到一定长度的文本或遇到标点符号时，进行语音合成
                  if (this.shouldSpeakText()) {
                     //this.speakText(this.accumulatedText)
                     //this.accumulatedText = ''  // 重置累积的文本
                  }
                }
              }
            } catch (e) {
              console.log('解析行数据出错:', e)
            }
          }
          this.speakText(this.accumulatedText)
          this.accumulatedText = ''
        }
        
        // 处理最后剩余的文本
        if (this.accumulatedText) {
          this.speakText(this.accumulatedText)
          this.accumulatedText = ''
        }

      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('请求被取消')
          return
        }
        console.error('发送请求失败:', error)
        alert('发送消息失败，请重试')
      } finally {
        this.currentController = null
        this.isProcessing = false
      }
    },

    shouldSpeakText() {
      // 检查是否应该开始语音播放
      // 当文本长度超过10个字符或遇到标点符号时开始播放
      const punctuationMarks = ['。', '！', '？', '.', '!', '?', '；', ';']
      return (
        this.accumulatedText.length > 10 ||
        punctuationMarks.some(mark => this.accumulatedText.includes(mark))
      )
    },

    cancelResponse() {
      if (this.currentController) {
        this.currentController.abort()
        this.currentController = null
      }
      // 停止当前语音播放
      if (this.isSpeaking) {
        this.synth.cancel()
        this.isSpeaking = false
        this.$emit('talkEnd')
      }
      // 清空语音队列
      this.speechQueue = []
    },

 
    handleNetworkError() {
      setTimeout(() => {
        if (this.isRecording) {
          this.restartRecognition()
        }
      }, 1000)
    },
 
    restartRecognition() {
      try {
        this.recognition.start()
      } catch (error) {
        console.error('Error restarting recognition:', error)
      }
    },
 
    async toggleRecording() {
      if (!this.selectedDevice) {
        alert('请先选择麦克风')
        return
      }
 
      if (!this.isRecording) {
        try {
          await navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: { exact: this.selectedDevice }
            }
          })
          this.recognition.start()
          this.isRecording = true
        } catch (error) {
          console.error('Error accessing microphone:', error)
          alert('无法访问麦克风')
        }
      } else {
        this.recognition.stop()
        this.isRecording = false
      }
    }
  },
 
  beforeDestroy() {
    this.cancelResponse()
    if (this.recognition) {
      this.recognition.stop()
    }
    // 清理语音合成
    if (this.synth) {
      this.synth.cancel()
    }
  }
  }
 </script>
 
 <style scoped>
.speech-recognition {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.text-panel {
  position: fixed;
  top: 480px !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  max-height: 60vh;
  overflow-y: auto;
  z-index: 1000;
  pointer-events: auto; /* 恢复鼠标事件 */
}

 
 .transcript-item {
  margin-bottom: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  position: relative;
 }
 
 .transcript-item.user {
  background-color: #e8f5e9;
  margin-left: 20px;
 }
 
 .transcript-item.ai {
  background-color: #e3f2fd;
  margin-right: 20px;
 }
 
 .message-tag {
  position: absolute;
  left: -20px;
  top: 8px;
  font-size: 12px;
  color: #666;
 }
 
 .interim-text {
  color: #666;
  font-style: italic;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-top: 8px;
 }
 
 .controls-panel {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  gap: 10px;
  align-items: center;
  pointer-events: auto;
 }
 
 .microphone-select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  min-width: 200px;
 }
 
 .dialog-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
 }
 
 .dialog-btn:hover {
  background-color: #45a049;
 }
 
 .dialog-btn.active {
  background-color: #f44336;
 }
 
 .dialog-btn.active:hover {
  background-color: #d32f2f;
 }
 
 .dialog-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
 }
 
 .recording-status {
  color: #f44336;
  font-size: 14px;
  animation: blink 1s infinite;
 }
 
 .processing-status {
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 
 .cancel-btn {
  padding: 4px 8px;
  font-size: 12px;
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
 }
 
 .cancel-btn:hover {
  background-color: #f4511e;
 }
 
 @keyframes blink {
  50% { opacity: 0.5; }
 }
 </style>
<template>
  <div class="app-layout">
    <div class="model-viewer-container">
      <model-viewer ref="modelViewer" />
    </div>
    <div class="bottom-panel">
      <speech-recognition 
        @talkStart="startTalking"
        @talkEnd="stopTalking"
      />
    </div>
  </div>
</template>

<script>
import ModelViewer from './components/ModelViewer.vue'
import SpeechRecognition from './components/SpeechRecognition.vue'

export default {
  name: 'App',
  components: {
    ModelViewer,
    SpeechRecognition
  },
  methods: {
    startTalking() {
      if (this.$refs.modelViewer) {
        this.$refs.modelViewer.startTalkAnimation()
      }
    },
    stopTalking() {
      if (this.$refs.modelViewer) {
        this.$refs.modelViewer.stopTalkAnimation()
      }
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: hidden;
}

#app {
  height: 100vh;
}

.app-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.model-viewer-container {
  flex: 1;
  overflow: hidden;
}

.bottom-panel {
  background: #fff;
  border-top: 1px solid #ddd;
  padding: 16px;
  z-index: 100;
}
</style>